import { Image } from '@graphcommerce/image'

type ResponsiveImageGridItemProps = {
  src: string
  alt?: string
  aspect?: string
  className?: string
  rounded?: boolean
}

export function ResponsiveImageGridItem(props: ResponsiveImageGridItemProps) {
  const { src, alt, aspect = '4/3', className, rounded } = props
  const aspectStyle = `aspect-[${aspect}]`
  return (
    <div
      className={`block items-center justify-center overflow-hidden ${
        rounded
          ? 'mx-auto aspect-square h-[80px] w-[80px] rounded-[50%] text-center md:h-[120px] md:w-[120px] lg:h-[180px] lg:w-[180px]'
          : `${aspectStyle} h-full w-full rounded-[4px] `
      }  ${className}`}
      style={{ aspectRatio: aspect }}
    >
      <Image
        className='h-full max-h-none w-full max-w-none rounded-[4px] object-cover delay-100 duration-300 ease-in-out hover:scale-125'
        src={src}
        layout='fill'
        alt={alt}
      />
    </div>
  )
}
