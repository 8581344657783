import { Image } from '@graphcommerce/image'
import { NoSsr } from '@mui/material'
import Link from 'next/link'
import amex from '../../assets/images/amex.svg'
import discover from '../../assets/images/discover.svg'
import background from '../../assets/images/footer-background.png'
import instagram from '../../assets/images/instagram.svg'
import madeInNY from '../../assets/images/made-in-NY.svg'
import mastercard from '../../assets/images/mastercard.svg'
import pinterest from '../../assets/images/pinterest.svg'
import tel from '../../assets/images/telephone.svg'
import templiQR from '../../assets/images/templi-qr.svg'
import tiktok from '../../assets/images/tiktok.svg'
import visa from '../../assets/images/visa.svg'
import { FooterQueryFragment } from '../Layout/FooterQueryFragment.gql'
import logoWhite from '../Layout/logo-white.svg'
import { SignupNewsletter } from '../Newsletter/SignupNewsletter'

type Social = {
  // Use any to avoid conflicts with @svgr/webpack plugin or babel-plugin-inline-react-svg plugin.
  // eslint-disable-next-line
  image: any
  href: string
  alt: string
  isMobile: boolean
}

export function FooterResponsive(props: FooterQueryFragment) {
  const { footer } = props
  const socials: Social[] = [
    {
      image: instagram,
      href: 'https://www.instagram.com/templi/',
      alt: 'instagram',
      isMobile: true,
    },
    {
      image: tiktok,
      href: 'https://www.tiktok.com/@templi_packaging',
      alt: 'tiktok',
      isMobile: true,
    },
    {
      image: pinterest,
      href: 'https://www.pinterest.com/templi_packaging/',
      alt: 'pinterest',
      isMobile: true,
    },
  ]

  return (
    <footer className='flex-col gap-y-10 bg-templi-purple px-[35px] pb-6 pt-6 text-pure-white md:px-[28px] lg:flex lg:p-0'>
      <div className='mt-10 flex w-full items-center justify-between gap-x-20 md:px-10 lg:items-start xl:px-[15%]'>
        <div className='mb-[18px] hidden min-w-[150px] flex-col items-start justify-center lg:flex'>
          <div className='flex h-full w-full flex-col items-center justify-start gap-y-5'>
            <Link href='/' legacyBehavior className='mb-3 mt-1 flex items-center justify-center'>
              <Image
                src={logoWhite}
                alt='Templi Logo'
                unoptimized
                layout='fixed'
                className='h-auto w-[80px]'
              />
            </Link>

            <div className='flex items-center justify-center'>
              <p className='block h-[58px] w-[58px]'>
                <Image src={madeInNY} alt='Made in NY' unoptimized />
              </p>
            </div>

            <ul className='flex gap-x-3'>
              {socials.map((social, i) => (
                <li key={social.href}>
                  <a href={social.href} target='_blank' rel='noreferrer'>
                    <Image src={social.image} alt='instagram' unoptimized />
                  </a>
                </li>
              ))}
            </ul>
            <span className='Type-Large-Regular lg:Type-XL-Regular mt-2 w-full text-center hover:text-pure-white-hover hover:underline'>
              <Link href='/templiservice/terms-and-conditions whitespace-nowrap'>
                Terms & Conditions
              </Link>
            </span>
            <span className='Type-Large-Regular lg:Type-XL-Regular w-full text-center hover:text-pure-white-hover hover:underline'>
              <Link href='/templiservice/privacy-policy whitespace-nowrap'>Privacy Policy</Link>
            </span>
          </div>
        </div>
        <div className='grid w-full grow-0 grid-cols-3 items-start justify-around lg:flex lg:items-start lg:gap-x-10'>
          <div className='w-full'>
            <p className='Type-XXL-Medium mb-4 text-pure-white'>Products</p>
            <ul className='justiy-start flex flex-col items-start gap-x-2 gap-y-2 lg:grid lg:grid-cols-2'>
              {footer?.topProducts?.map((product) => (
                <li key={product.label}>
                  <span className='Type-Large-Regular lg:Type-XL-Regular hover:text-pure-white-hover hover:underline'>
                    <Link href={product.url}>{product.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className='w-full'>
            <p className='Type-XXL-Medium mb-4 text-pure-white'>About us</p>
            <ul className='flex flex-col gap-y-2'>
              {footer?.aboutUs?.map((resource) => (
                <li key={resource.label}>
                  <span className='Type-Large-Regular lg:Type-XL-Regular hover:text-pure-white-hover hover:underline'>
                    <Link href={resource.url}>{resource.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className='w-full'>
            <p className='Type-XXL-Medium mb-4 text-pure-white'>Help</p>
            <ul className='flex flex-col gap-y-2'>
              {footer?.resources?.map((resource) => (
                <li key={resource.label}>
                  <span className='Type-Large-Regular lg:Type-XL-Regular hover:text-pure-white-hover hover:underline'>
                    <Link href={resource.url}>{resource.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='hidden h-full flex-col items-center justify-between gap-[35px] lg:flex'>
          <div className='epadding-[5px] w-full flex-col items-start justify-start lg:flex'>
            <span className='Type-XXL-Medium text-pure-white lg:mb-4'>Get in touch</span>
            <span className='mb-[10px] grid grid-cols-[10fr_80fr] items-center justify-between'>
              <span className='material-symbols-outlined mr-[5px]'>mail</span>
              <span className='Type-XL-Regular ml-[2px]'>
                <a href='mailto:contact@templi.com'>contact@templi.com</a>
              </span>
            </span>
            <span className='grid grid-cols-[20fr_80fr] items-center justify-center'>
              <span className='material-symbols-outlined mr-[5px]'>call</span>
              <img src={tel.src} className='w-full' alt='telephone number' />
            </span>
          </div>
        </div>
      </div>
      <div className='mt-10 flex w-full flex-col items-center justify-start gap-5'>
        <ul className='flex h-full w-full justify-center gap-x-5 md:gap-x-4'>
          <li>
            <Image src={visa} alt='Visa' unoptimized />
          </li>
          <li>
            <Image src={amex} alt='Amex' unoptimized />
          </li>
          <li>
            <Image src={discover} alt='Discover' unoptimized />
          </li>
          <li>
            <Image src={mastercard} alt='Mastercard' unoptimized />
          </li>
        </ul>

        <p className='Type-Small-Regualr text-center'>&copy;Templi 2023. All rights reserved.</p>
      </div>
    </footer>
  )
}
